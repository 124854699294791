
import React from 'react';

import {connect} from 'react-redux';



import './proyectos.scss';






const Pproyectos = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id64a1fa07a52ebcacc7a8284c" >

      <div id="ia1f" className="">
      
      <div id="imbi" className="">
      
      <div id="ibno" className="">
      
        <a
          target=""
          className=""
          id="iilv3k"
          href="/"
        >
          
      <img
        className=""
        id="i108e"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iynmw" className="">
      
        <a
          target=""
          className=""
          id="ie17s"
          href="/exitos"
        >
          
    <div id="ioegt" className="">
    <span>Historia de Éxito</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="ikkcj"
          href="proyectos"
        >
          
    <div id="i19l7" className="">
    <span>Nuevos Proyectos</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="is0ec"
          href="/noticias"
        >
          
    <div id="ins3p" className="">
    <span>Noticias</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="i4by9" className="">
      
      <div id="i66ze8" className="">
      
      <p className="" id="ilmxru">
        <span>NUESTROS PROYECTOS</span>
      <br className="" id="" />
      <span>FUNDACIÓN STARTUP</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i1rj1" className="">
      
      <div id="i24u" className="">
      
      <div id="iacze" className="">
      
      <div id="id1x8" className="">
      
      <p className="" id="indc8">
        <span>NASA SPACE APP CHALLENGE</span>
      </p>
      
      <p className="" id="i42b9">
        <span>Space App Challenge, es un desafío internacional para impulsar la creación de soluciones innovadoras a problemas tanto fuera como dentro de nuestro planeta Tierra.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i2vhr" className="">
      
      <div id="ir9gz" className="">
      
      <img
        className=""
        id="ifjah"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm0gb1a.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ivc3k" className="">
      
      <div id="i1emi" className="">
      
      <div id="i0g3y" className="">
      
      <div id="ig7p8" className="">
      
      <img
        className=""
        id="i66im"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm0kdrp.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ib9rf" className="">
      
      <div id="i1drf" className="">
      
      <p className="" id="ilut6">
        <span>Bootcamp Mentalidad</span>
      <br className="" id="" />
      <span>Emprendedora</span>
      </p>
      
      <p className="" id="ila5w">
        <span>Desarrollo de talleres presenciales en escuelas, colegios y otro tipo de organizaciones, con la finalidad de ir sembrando la semilla del emprendimiento y la innovación en los niños y adolescentes, futuro semillero.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iyciof" className="">
      
      <div id="ii4j1d" className="">
      
      <div id="iik3ch" className="">
      
      <div id="i7p8be" className="">
      
      <p className="" id="izv3h">
        <span>Incubación de cuerpo docente a nivel de prebásica, básica y pregrado</span>
      </p>
      
      <p className="" id="ic9hkt">
        <span>Incubación de cuerpo docente a nivel de prebásica, básica y pregrado</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ioc4mz" className="">
      
      <div id="iqnpga" className="">
      
      <img
        className=""
        id="imwkph"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm0llrh.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i4w64g" className="">
      
      <div id="i2t3zn" className="">
      
      <div id="iry76g" className="">
      
      <div id="ihc2oj" className="">
      
      <img
        className=""
        id="ir4kvv"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm0n04o.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ixsgcj" className="">
      
      <div id="invsui" className="">
      
      <p className="" id="ii828c">
        <span>Creación e implementación de un programa de transformación digital de PYMES</span>
      </p>
      
      <p className="" id="ifk9us">
        <span>Brindar la oportunidad a todos los pequeños y medianos emprendimientos tradicionales para realizar su transición a entornos digitales.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="igzw0l" className="">
      
      <div id="iui5m6" className="">
      
      <div id="ivwwk6" className="">
      
      <div id="itbv53" className="">
      
      <p className="" id="icdaj7">
        <span>Cápsulas educativas a través de medios de comunicación</span>
      </p>
      
      <p className="" id="iodoqv">
        <span>Alianza con diferentes medios de comunicación, para la producción de cápsulas educativas relacionadas con emprendimientos tecnológicos</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i9dm38" className="">
      
      <div id="i0saor" className="">
      
      <img
        className=""
        id="idn7bw"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm0nim8.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0iyzd" className="">
      
      <div id="ilhtbc" className="">
      
      <div id="ie903g" className="">
      
      <div id="id9pbl" className="">
      
      <img
        className=""
        id="i7n5th"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm0qoev.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ib7llq" className="">
      
      <div id="i5x7j9" className="">
      
      <p className="" id="i72h37">
        <span>Viajes a Silicon Valley</span>
      </p>
      
      <p className="" id="inzi8l">
        <span>Tours guiados dirigidos a jóvenes para despertar en ellos el interés por el emprendimiento tecnológico, quienes serán los futuros Angel Investors y el patrocinio a startups destacadas del programa Honduras Digital Challenge.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ibpt" className="">
      
      <div id="il9wr2" className="">
      
      <img
        className=""
        id="io9efz"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
      <div id="ikymgq" className="">
      
      <p className="" id="i1pb9i">
        <span>Contacto</span>
      </p>
      
      <p className="" id="i4l6j8">
        <span>+504 9670-6970</span>
      </p>
      
      </div>
      
      <div id="idswjw" className="">
      
      <p className="" id="in2cme">
        <span>Dirección</span>
      </p>
      
      <p className="" id="icwv4n">
        <span>Blvd. Suyapa, Paseo Colonial Trapiche, Edificio Distrito Artemisa, Nivel 17, Oficina 23. Tegucigalpa, Honduras.</span>
      </p>
      
      </div>
      
      <div id="iovyfg" className="">
      
      <p className="" id="i2tl07">
        <span>Redes Sociales</span>
      </p>
      
      <div id="ih3vwx" className="">
      
        <a
          target=""
          className=""
          id="iuktqg"
          href="https://www.facebook.com/fundacionstartup"
        >
          
      <img
        className=""
        id="ibn6mg"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls1tpc.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io1est"
          href="https://www.instagram.com/fundacionstartup/"
        >
          
      <img
        className=""
        id="izd5ra"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls4n4k.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i5prdq"
          href="https://twitter.com/HD_Challenge"
        >
          
      <img
        className=""
        id="iwh6wv"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls56uq.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pproyectos);
  