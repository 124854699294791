
import React from 'react';

import {connect} from 'react-redux';



import './noticias.scss';






const Pnoticias = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id64a1fff1a52ebcacc7a82be9" >

      <div id="ia1f" className="">
      
      <div id="imbi" className="">
      
      <div id="ibno" className="">
      
        <a
          target=""
          className=""
          id="i28lah"
          href="/"
        >
          
      <img
        className=""
        id="i108e"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iynmw" className="">
      
        <a
          target=""
          className=""
          id="ie17s"
          href="/exitos"
        >
          
    <div id="ioegt" className="">
    <span>Historia de Éxito</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="ikkcj"
          href="proyectos"
        >
          
    <div id="i19l7" className="">
    <span>Nuevos Proyectos</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="is0ec"
          href="/noticias"
        >
          
    <div id="ins3p" className="">
    <span>Noticias</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="i4by9" className="">
      
      <div id="igv67v" className="">
      
      <div id="ikqn4c" className="">
      
      <p className="" id="im9ezj">
        <span>Nuestras Noticias de Fundación Startup</span>
      </p>
      
      </div>
      
      <div id="i7puxg" className="">
      
      <p className="" id="iqebqz">
        <span>Se parte de nuestra comunidad y entérate de nuestras noticias dando clic al siguiente botón.</span>
      </p>
      
        <a
          target=""
          className=""
          id="innq65"
          href="#"
        >
          
    <div id="i3g15v" className="">
    <span>Inscribirme</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i24u" className="">
      
      <div id="igz0mb" className="">
      
      <div id="izq1jd" className="">
      
      <img
        className=""
        id="i5ypu1"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1bucq.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ihocpf" className="">
      
      <img
        className=""
        id="i1plc7"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1c69r.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="itlxg" className="">
      
      <div id="il755" className="">
      
      <p className="" id="iwc4n">
        <span>NOTICIAS DESTADAS</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0aghw" className="">
      
      <div id="iaf6al" className="">
      
      <div id="ies19" className="">
      
      <div id="id3yc" className="">
      
      <img
        className=""
        id="iz8pt"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1hts1.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="isyap">
        <span>07/04/2023</span>
      </p>
      
      <p className="" id="ijlag">
        <span>Titulo</span>
      </p>
      
      <p className="" id="iz8sn">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id fringilla turpis tempor enim. Vel tristique suspendisse eget sodales egestas. Donec duis lacus, odio velit</span>
      <span className="" id="imky5r">
        
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="is6hp9"
          href="#"
        >
          
    <div id="if2qmk" className="">
    <span>Ver más</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="i06clj" className="">
      
      <div id="i731fh" className="">
      
      <img
        className=""
        id="iw4hug"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1hts1.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ixmebh">
        <span>07/04/2023</span>
      </p>
      
      <p className="" id="is2fwg">
        <span>Titulo</span>
      </p>
      
      <p className="" id="iecbl6">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id fringilla turpis tempor enim. Vel tristique suspendisse eget sodales egestas. Donec duis lacus, odio velit</span>
      <span className="" id="ilyu89">
        
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="i0hqxk"
          href="#"
        >
          
    <div id="is8sge" className="">
    <span>Ver más</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="i41rjr" className="">
      
      <div id="iue5gg" className="">
      
      <img
        className=""
        id="iz8yja"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1hts1.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i9okmh">
        <span>07/04/2023</span>
      </p>
      
      <p className="" id="in91ns">
        <span>Titulo</span>
      </p>
      
      <p className="" id="iu6fio">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id fringilla turpis tempor enim. Vel tristique suspendisse eget sodales egestas. Donec duis lacus, odio velit</span>
      <span className="" id="ie5xhp">
        
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="i1vfcq"
          href="#"
        >
          
    <div id="ilfntw" className="">
    <span>Ver más</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="ibcwk2" className="">
      
      <div id="i0cnee" className="">
      
      <img
        className=""
        id="ijwj1h"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1hts1.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i4gh3j">
        <span>07/04/2023</span>
      </p>
      
      <p className="" id="io1bu7">
        <span>Titulo</span>
      </p>
      
      <p className="" id="irgu4l">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id fringilla turpis tempor enim. Vel tristique suspendisse eget sodales egestas. Donec duis lacus, odio velit</span>
      <span className="" id="ibmbhh">
        
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="imkul6"
          href="#"
        >
          
    <div id="itxd3x" className="">
    <span>Ver más</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="i0pprh" className="">
      
      <div id="iwb35v" className="">
      
      <img
        className=""
        id="ixyn74"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1hts1.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="izsk7u">
        <span>07/04/2023</span>
      </p>
      
      <p className="" id="izwakj">
        <span>Titulo</span>
      </p>
      
      <p className="" id="iw6zhp">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id fringilla turpis tempor enim. Vel tristique suspendisse eget sodales egestas. Donec duis lacus, odio velit</span>
      <span className="" id="icbqc7">
        
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="ihlgvf"
          href="#"
        >
          
    <div id="i2vwk2" className="">
    <span>Ver más</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="iwvop1" className="">
      
      <div id="ihcxxn" className="">
      
      <img
        className=""
        id="ir6v41"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljm1hts1.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ix8cy9">
        <span>07/04/2023</span>
      </p>
      
      <p className="" id="irfw2p">
        <span>Titulo</span>
      </p>
      
      <p className="" id="iv8u8l">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id fringilla turpis tempor enim. Vel tristique suspendisse eget sodales egestas. Donec duis lacus, odio velit</span>
      <span className="" id="ia7j9g">
        
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="iqrxq8"
          href="#"
        >
          
    <div id="iaysa2" className="">
    <span>Ver más</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="ijuxsj" className="">
      
      <button
        type=""
        name=""
        id=""
        className="standard_button "
        
      >
      
      <div id="ict50g" className="">
      <span>Ver todas las noticias</span>
      </div>
      
      </button>
      
      </div>
      
      <div id="ibpt" className="">
      
      <div id="il9wr2" className="">
      
      <img
        className=""
        id="io9efz"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
      <div id="ikymgq" className="">
      
      <p className="" id="i1pb9i">
        <span>Contacto</span>
      </p>
      
      <p className="" id="i4l6j8">
        <span>+504 9670-6970</span>
      </p>
      
      </div>
      
      <div id="idswjw" className="">
      
      <p className="" id="in2cme">
        <span>Dirección</span>
      </p>
      
      <p className="" id="icwv4n">
        <span>Blvd. Suyapa, Paseo Colonial Trapiche, Edificio Distrito Artemisa, Nivel 17, Oficina 23. Tegucigalpa, Honduras.</span>
      </p>
      
      </div>
      
      <div id="iovyfg" className="">
      
      <p className="" id="i2tl07">
        <span>Redes Sociales</span>
      </p>
      
      <div id="ih3vwx" className="">
      
        <a
          target=""
          className=""
          id="iuktqg"
          href="https://www.facebook.com/fundacionstartup"
        >
          
      <img
        className=""
        id="ibn6mg"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls1tpc.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io1est"
          href="https://www.instagram.com/fundacionstartup/"
        >
          
      <img
        className=""
        id="izd5ra"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls4n4k.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i5prdq"
          href="https://twitter.com/HD_Challenge"
        >
          
      <img
        className=""
        id="iwh6wv"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls56uq.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pnoticias);
  