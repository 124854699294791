
import React from 'react';

import {connect} from 'react-redux';



import './historiaexito.scss';






const Phistoriaexito = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id64a1f35ea52ebcacc7a8261e" >

      <div id="ia1f" className="">
      
      <div id="imbi" className="">
      
      <div id="ibno" className="">
      
        <a
          target=""
          className=""
          id="i28lah"
          href="/"
        >
          
      <img
        className=""
        id="i108e"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iynmw" className="">
      
        <a
          target=""
          className=""
          id="ie17s"
          href="/exitos"
        >
          
    <div id="ioegt" className="">
    <span>Historia de Éxito</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="ikkcj"
          href="proyectos"
        >
          
    <div id="i19l7" className="">
    <span>Nuevos Proyectos</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="is0ec"
          href="/noticias"
        >
          
    <div id="ins3p" className="">
    <span>Noticias</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="i4by9" className="">
      
      <div id="ivo4oy" className="">
      
      <div id="iff0og" className="">
      
      <div id="io3epb" className="">
      
      <img
        className=""
        id="ilpr3f"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlzbofo.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="ig6sjz" className="">
      
      <div id="ichvfw" className="">
      
      <p className="" id="is8juy">
        <span>HISTORIA DE EXITOS</span>
      </p>
      
      <p className="" id="iy7vll">
        <span>La Fundación Startup tiene como objetivo llevar a cabo un cambio positivo mediante diversos programas, brindando las herramientas y el apoyo necesario para las personas que deseen desarrollarse como emprendedores.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i24u" className="">
      
      <div id="itlxg" className="">
      
      <div id="il755" className="">
      
      <p className="" id="iwc4n">
        <span>HISTORIAS DESTACADAS</span>
      </p>
      
      </div>
      
      <div id="iaf6al" className="">
      
      <div id="ies19" className="">
      
      <div id="id3yc" className="">
      
      <img
        className=""
        id="iz8pt"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlzpfsz.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="isyap">
        <span>Lofty Apps</span>
      </p>
      
      <p className="" id="ijlag">
        <span>Tecnología</span>
      </p>
      
      </div>
      
      <div id="ivl3ky" className="">
      
      <div id="i3sy1p" className="">
      
      <img
        className=""
        id="ibfrq1"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlzrbc2.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i39ocu">
        <span>Doctor 7</span>
      </p>
      
      <p className="" id="imqqh9">
        <span>Salud</span>
      </p>
      
      </div>
      
      <div id="i07ixz" className="">
      
      <div id="iofcyo" className="">
      
      <img
        className=""
        id="imkffj"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlzs81a.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ivd9cj">
        <span>Salutec</span>
      </p>
      
      <p className="" id="i8qejf">
        <span>Salud</span>
      </p>
      
      </div>
      
      <div id="i5g95x" className="">
      
      <div id="ic336q" className="">
      
      <img
        className=""
        id="it75ku"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxduz2bw.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="iy2sel">
        <span>Do it Learning Center</span>
      </p>
      
      <p className="" id="ihqfgl">
        <span>Educación</span>
      </p>
      
      </div>
      
      <div id="i8v3c6" className="">
      
      <div id="i57sva" className="">
      
      <img
        className=""
        id="imcp0c"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlztxex.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ifwopl">
        <span>Realidad Virtual Honduras</span>
      </p>
      
      <p className="" id="ipm8ws">
        <span>Tecnología</span>
      </p>
      
      </div>
      
      <div id="iuvehk" className="">
      
      <div id="iv381z" className="">
      
      <img
        className=""
        id="iowe1m"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlzv22i.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i4k1ae">
        <span>GEOHOME</span>
      </p>
      
      <p className="" id="iakrka">
        <span>Inmobiliaria</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ibpt" className="">
      
      <div id="il9wr2" className="">
      
      <img
        className=""
        id="io9efz"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
      <div id="ikymgq" className="">
      
      <p className="" id="i1pb9i">
        <span>Contacto</span>
      </p>
      
      <p className="" id="i4l6j8">
        <span>+504 9670-6970</span>
      </p>
      
      </div>
      
      <div id="idswjw" className="">
      
      <p className="" id="in2cme">
        <span>Dirección</span>
      </p>
      
      <p className="" id="icwv4n">
        <span>Blvd. Suyapa, Paseo Colonial Trapiche, Edificio Distrito Artemisa, Nivel 17, Oficina 23. Tegucigalpa, Honduras.</span>
      </p>
      
      </div>
      
      <div id="iovyfg" className="">
      
      <p className="" id="i2tl07">
        <span>Redes Sociales</span>
      </p>
      
      <div id="ih3vwx" className="">
      
        <a
          target=""
          className=""
          id="iuktqg"
          href="https://www.facebook.com/fundacionstartup"
        >
          
      <img
        className=""
        id="ibn6mg"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls1tpc.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io1est"
          href="https://www.instagram.com/fundacionstartup/"
        >
          
      <img
        className=""
        id="izd5ra"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls4n4k.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i5prdq"
          href="https://twitter.com/HD_Challenge"
        >
          
      <img
        className=""
        id="iwh6wv"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls56uq.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Phistoriaexito);
  