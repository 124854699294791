
import React from 'react';

import {connect} from 'react-redux';



import './initial.scss';






const Pinitial = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id64a1b6f4a52ebcacc7a819c4" >

      <div id="ia1f" className="">
      
      <div id="imbi" className="">
      
      <div id="ibno" className="">
      
        <a
          target=""
          className=""
          id="ibydwj"
          href="/"
        >
          
      <img
        className=""
        id="i108e"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iynmw" className="">
      
        <a
          target=""
          className=""
          id="ie17s"
          href="/exitos"
        >
          
    <div id="ioegt" className="">
    <span>Historia de Éxito</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="ikkcj"
          href="/proyectos"
        >
          
    <div id="i19l7" className="">
    <span>Nuevos Proyectos</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="is0ec"
          href="/noticias"
        >
          
    <div id="ins3p" className="">
    <span>Noticias</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="i4by9" className="">
      
      <div id="ic64a" className="">
      
      <p className="" id="i3uqd">
        <span>Fundación Startup</span>
      </p>
      
      <p className="" id="iev82">
        <span>La Fundación Startup es una iniciativa de Banco Atlántida, con el enfoque de poder apoyar el crecimiento económico, al crear un ecosistema que promueva y genere oportunidades para la innovación y el emprendimiento.</span>
      </p>
      
      </div>
      
      <div id="imh9z" className="">
      
      <img
        className=""
        id="ikgb1"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlqbhsk.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i24u" className="">
      
      <div id="ipsvi" className="">
      
      <p className="" id="iec46">
        <span>Alcance</span>
      </p>
      
      <p className="" id="i50q8">
        <span>DESDE SU INICIO EN EL AÑO 2017 Y HASTA LA FECHA:</span>
      </p>
      
      <div id="iq5gi" className="">
      
      <div id="if80e" className="">
      
      <div id="iioxf" className="">
      
      <p className="" id="igifc">
        <span>2000+</span>
      </p>
      
      <p className="" id="ict19">
        <span>Emprendedores tecnológicos capacitados</span>
      </p>
      
      </div>
      
      <div id="if98w" className="">
      
      </div>
      
      </div>
      
      <div id="ildhl" className="">
      
      <div id="i83bg" className="">
      
      <p className="" id="ivxci">
        <span>100+</span>
      </p>
      
      <p className="" id="i79v7">
        <span>Startups Pista de Vuelo</span>
      </p>
      
      </div>
      
      <div id="iwdoi" className="">
      
      </div>
      
      </div>
      
      <div id="ia7dj" className="">
      
      <div id="io77k" className="">
      
      <p className="" id="i6o5k">
        <span>200+</span>
      </p>
      
      <p className="" id="ikbpu">
        <span>Startups Pista de Idea</span>
      </p>
      
      </div>
      
      <div id="ietdi" className="">
      
      </div>
      
      </div>
      
      <div id="izc0a" className="">
      
      <div id="ikst2" className="">
      
      <p className="" id="iq68l">
        <span>$300,000</span>
      </p>
      
      <p className="" id="i5jxk">
        <span>Capital semilla entregado al sector digital</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="itlxg" className="">
      
      <div id="il755" className="">
      
      <p className="" id="iwc4n">
        <span>OBJETIVO</span>
      </p>
      
      </div>
      
      <p className="" id="ibn32">
        <span>La Fundación Startup tiene como objetivo llevar a cabo un cambio positivo mediante diversos programas, brindando las herramientas y el apoyo necesario para las personas que deseen desarrollarse como emprendedores. </span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <div id="iemjyh" className="">
      
      <div id="i1vsxi" className="">
      
      <p className="" id="ij595">
        <span>VALORES DE LA FUNDACIÓN STARTUP</span>
      </p>
      
      </div>
      
      <div id="iah7g2" className="">
      
      <div id="iyyndf" className="">
      
      <div id="imoxx9" className="">
      
      <div id="ine6zj" className="">
      
      <img
        className=""
        id="idv65c"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlrepy6.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ilgmdj">
        <span>Innovación</span>
      </p>
      
      </div>
      
      <div id="iumsaf" className="">
      
      <div id="i7c9sk" className="">
      
      <img
        className=""
        id="iwrk8t"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlrepy6.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ika4uq">
        <span>Solidaridad</span>
      </p>
      
      </div>
      
      <div id="irn1s3" className="">
      
      <div id="itycxc" className="">
      
      <img
        className=""
        id="i5xgsm"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlrepy6.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i5zmr1">
        <span>Transformación</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i3x86l" className="">
      
      <div id="ir78yi" className="">
      
      <div id="ig0h1h" className="">
      
      <img
        className=""
        id="ie3e9g"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlrepy6.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i15m4u">
        <span>Transparencia</span>
      </p>
      
      </div>
      
      <div id="i42t6p" className="">
      
      <div id="ii63fo" className="">
      
      <img
        className=""
        id="ia38d2"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlrepy6.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="i79oed">
        <span>Integridad</span>
      </p>
      
      </div>
      
      <div id="i884na" className="">
      
      <div id="iotxku" className="">
      
      <img
        className=""
        id="ilo9z6"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlrepy6.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ierudh">
        <span>Inclusión</span>
      </p>
      
      </div>
      
      </div>
      
      <img
        className=""
        id="izygw2"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlre4sd.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="im7xx" className="">
      
      <div id="iubag" className="">
      
      <p className="" id="iu02a">
        <span>NUESTRA META</span>
      </p>
      
      </div>
      
      <div id="i6aojl" className="">
      
      <div id="ihvb7p" className="">
      
      <p className="" id="ibz6wj">
        <span>Fortalecer el ecosistema digital del país y la región a través del desarrollo de la industria digital esto lo logramos</span>
      </p>
      
      </div>
      
      <div id="ivsgoe" className="">
      
      <div id="inu2v8" className="">
      
      <p className="" id="iue7mc">
        <span>1</span>
      </p>
      
      <p className="" id="i1v13n">
        <span>Apoyando a las Startups a través de educación, recursos, networking y herramientas</span>
      </p>
      
      </div>
      
      <div id="imo805" className="">
      
      <p className="" id="iiaem8">
        <span>2</span>
      </p>
      
      <p className="" id="i4mi5e">
        <span>Fomentando la Transformación Digital PYME e Incentivando una cultura de emprendimiento</span>
      </p>
      
      </div>
      
      <div id="imu1dg" className="">
      
      <p className="" id="iub58u">
        <span>3</span>
      </p>
      
      <p className="" id="ib6q4s">
        <span>Brindando espacios seguros que promueven el desarrollo, creatividad e innovación</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ibpt" className="">
      
      <div id="il9wr2" className="">
      
      <img
        className=""
        id="io9efz"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljlq1jg9.png"
        alt="undefined"
      />
      
      <div id="ikymgq" className="">
      
      <p className="" id="i1pb9i">
        <span>Contacto</span>
      </p>
      
      <p className="" id="i4l6j8">
        <span>+504 9670-6970</span>
      </p>
      
      </div>
      
      <div id="idswjw" className="">
      
      <p className="" id="in2cme">
        <span>Dirección</span>
      </p>
      
      <p className="" id="icwv4n">
        <span>Blvd. Suyapa, Paseo Colonial Trapiche, Edificio Distrito Artemisa, Nivel 17, Oficina 23. Tegucigalpa, Honduras.</span>
      </p>
      
      </div>
      
      <div id="iovyfg" className="">
      
      <p className="" id="i2tl07">
        <span>Redes Sociales</span>
      </p>
      
      <div id="ih3vwx" className="">
      
        <a
          target="_blank"
          className=""
          id="iuktqg"
          href="https://www.facebook.com/fundacionstartup"
        >
          
      <img
        className=""
        id="ibn6mg"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls1tpc.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target="_blank"
          className=""
          id="io1est"
          href="https://www.instagram.com/fundacionstartup/"
        >
          
      <img
        className=""
        id="izd5ra"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls4n4k.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i5prdq"
          href="https://twitter.com/HD_Challenge"
        >
          
      <img
        className=""
        id="iwh6wv"
        src="https://assetsprojects.s3.amazonaws.com/404p7niljls56uq.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pinitial);
  